import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaPinterest
} from "react-icons/fa"
import { IoIosArrowRoundUp } from "react-icons/io"
import { Link } from "react-router-dom"
import { animateScroll } from "react-scroll"
import { SubscribeEmailTwo, CustomForm } from "../Newsletter"

const FooterTwo = () => {
  const [scroll, setScroll] = useState(0)
  const [top, setTop] = useState(0)
  const { t } = useTranslation()

  useEffect(() => {
    setTop(100)
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    animateScroll.scrollToTop()
  }

  const handleScroll = () => {
    setScroll(window.scrollY)
  }

  return (
    <footer
      className="space-pt--100 space-pb--50"
      style={{ backgroundColor: "#f7f7f7", color: "black" }}
    >
      <Container className="wide">
        <Row>
          <Col className="footer-single-widget space-mb--50">
            {/* logo */}
            <div className="logo space-mb--35">
              <img
                src="/images/Logo-new.png"
                className="img-fluid"
                width="150px"
                alt=""
              />
            </div>

            {/*=======  copyright text  =======*/}
            <div
              className="footer-single-widget__copyright"
              style={{ color: "black" }}
            >
              &copy; {new Date().getFullYear() + " "}
              <a href="" target="_blank">
                lovely house
              </a>
              <span>{t("footer.rigth")}</span>
            </div>
          </Col>

          <Col
            className="footer-single-widget space-mb--50"
            style={{ color: "black" }}
          >
            <h5
              className="footer-single-widget__title"
              style={{ color: "black" }}
            >
              {t("footer.link")}
            </h5>
            <nav className="footer-single-widget__nav">
              <ul>
                {/* <li>
                  <a href="#">Returns</a>
                </li> */}
                <li>
                  <Link to="/condition" style={{ color: "black" }}>
                    {t("footer.policy")}
                  </Link>
                </li>
              </ul>
            </nav>
          </Col>

          <Col className="footer-single-widget space-mb--50">
            <h5
              className="footer-single-widget__title"
              style={{ color: "black" }}
            >
              {t("footer.follow")}
            </h5>
            <nav className="footer-single-widget__nav footer-single-widget__nav--social">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/LovelyHouse-Paris-106769757673769"
                    style={{ color: "black" }}
                  >
                    <FaFacebookF /> Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com"
                    style={{ color: "black" }}
                  >
                    <FaInstagram /> Instagram
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.pinterest.fr/rissjay/_saved/"
                    style={{ color: "black" }}
                  >
                    <FaPinterest /> Pinterest
                  </a>
                </li>
              </ul>
            </nav>
          </Col>

          <Col className="footer-single-widget space-mb--50">
            <div className="footer-subscribe-widget">
              <h2
                className="footer-subscribe-widget__title"
                style={{ color: "black" }}
              >
                {t("footer.subs")}.
              </h2>
              <p className="footer-subscribe-widget__subtitle">
                {t("footer.subs-text")}.
              </p>
              {/* email subscription */}
              <SubscribeEmailTwo />
            </div>
          </Col>
        </Row>
      </Container>
      <button
        className={`scroll-top ${scroll > top ? "show" : ""}`}
        onClick={() => scrollToTop()}
      >
        <IoIosArrowRoundUp />
      </button>
    </footer>
  )
}

export default FooterTwo

import React from "react";
import { Button } from "react-bootstrap";
import { LayoutFive } from "../../components/Layout";
import { ContainHome } from "../home/home.style";
import { useForm } from "react-hook-form";
import axios from "axios";
import { message, notification } from "antd";
import Banner from "../../components/Banner/Banner";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { register, handleSubmit, errors, reset } = useForm();
  const { t } = useTranslation();

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: "Contact",
      description: message,
    });
  };

  const onSubmit = async (data, e) => {
    const response = await axios.post(
      `${process.env.REACT_APP_REST_API_URL}api/other/contact`,
      data
    );

    if (response) {
      // message.success(response.data.message);
      openNotificationWithIcon("success", response.data.message);
    }
    e.target.reset();
  };

  return (
    <LayoutFive>
      <ContainHome>
        <Banner
          title="Contact"
          image="/images/banners/banner.jpg"
          link="contactez-nous"
        />
        <section id="contacts">
          <div className="section bg-dots">
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-3">
                    <div className="section-info">
                      <div className="title-hr wow fadeInLeft"></div>
                      <div className="info-title">
                        {t("contact.title-left-1")}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="row-contact row">
                      <div className="col-contact col-lg-6">
                        <p className="contact-address text-muted">
                          <strong>Rue Victor Hugo, 95300 Pontoise</strong>
                        </p>
                        <p className="contact-address contact-top">
                          Pontoise, <span>France</span>
                        </p>
                        <p className="contact-row">
                          <strong className="text-dark">Email:</strong>{" "}
                          info@lovelyhouse.fr
                        </p>
                      </div>
                      <div className="col-contact col-lg-6">
                        <p className="contact-top">
                          <strong className="text-muted">
                            {t("contact.title-right-1")}:
                          </strong>
                        </p>
                        <p className="phone-lg text-dark">+33 1 85 11 28 39</p>
                        <div className="">
                          <strong className="text-dark">
                            {t("contact.Qtn-text")}
                          </strong>
                          <div className="mt-3">
                            <a
                              href="https://docs.google.com/forms/d/1yW4TMozQhtnHRbCPREutZOuSfbtPsnvnH3uzmtB0SRY/edit?usp=sharing"
                              className="btn-upper btn-yellow btn"
                            >
                              Questionnaire
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-message">
              <div className="container">
                <div className="row">
                  <div className="col-md-3">
                    <div className="section-info">
                      <div className="title-hr wow fadeInLeft"></div>
                      <div className="info-title">
                        {t("contact.title-left-2")}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <form onSubmit={handleSubmit(onSubmit)} className="js-form">
                      <div className="row">
                        <div className="form-group col-sm-6 col-lg-4">
                          <input
                            ref={register({ required: true })}
                            className="input-gray"
                            type="text"
                            name="name"
                            // required
                            placeholder={`${t("contact.placeholder-name")} *`}
                          />
                          {errors.name && <p>Obligatoire</p>}
                        </div>
                        <div className="form-group col-sm-6 col-lg-4">
                          <input
                            ref={register({ required: true })}
                            className="input-gray"
                            type="email"
                            name="email"
                            placeholder="Email *"
                          />
                          {errors.email && <p>Obligatoire</p>}
                        </div>
                        <div className="form-group col-sm-6 col-lg-4">
                          <input
                            ref={register({ required: true })}
                            className="input-gray"
                            type="text"
                            name="phone"
                            placeholder="N° Telephone *"
                          />
                          {errors.phone && <p>Obligatoire</p>}
                        </div>
                        <div className="form-group col-sm-12 col-lg-4">
                          <input
                            ref={register({ required: true })}
                            className="input-gray"
                            type="text"
                            name="subject"
                            placeholder={`${t(
                              "contact.placeholder-subject"
                            )} (requis)`}
                          />
                        </div>
                        {errors.subjet && <p>Obligatoire</p>}
                        <div className="form-group col-sm-12">
                          <textarea
                            ref={register({ required: true })}
                            className="input-gray"
                            name="message"
                            // required
                            placeholder="Message*"
                          ></textarea>
                          {errors.message && <p>Obligatoire</p>}
                        </div>
                        <div
                          className="col-sm-12"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            type="submit"
                            className="btn-upper btn-yellow btn"
                          >
                            {t("contact.btn-text")}
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </ContainHome>
    </LayoutFive>
  );
};

export default Contact;
